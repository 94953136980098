import styled, { css } from 'styled-components'
import { media } from 'styles/utils'

export const Wrapper = styled.div`
  background-image: url(${props => props.theme.heroBg}),
    linear-gradient(
      to right,
      ${props => props.theme.gradientStart},
      ${props => props.theme.gradientEnd}
    );
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: calc(100vh - 370px);

  ${props =>
    props.small &&
    css`
      min-height: auto;
    `}
  ${media.medium`
    padding: 10px;
  `}
`
