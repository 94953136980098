export const getGamerules = state => state.gamerules

export const getSelectedGame = state => {
  const gamerules = state.gamerules
  let selectedAddress = state.meta.selectedAddress

  if (!gamerules.orderedOdds) return {}

  let selectedGame = gamerules.addresses[selectedAddress]

  if (!selectedGame) {
    const middle = Math.floor(gamerules.orderedOdds.length / 2)
    selectedAddress = gamerules.orderedOdds[middle].address
    state.meta.selectedAddress = selectedAddress
    selectedGame = gamerules.addresses[selectedAddress]
  }

  return selectedGame
}

export const getGamescount = state => state.gamerules.gamescount
