import styled, { css } from 'styled-components'
import { media } from 'styles/utils'
import Container from 'atoms/Container'

export const Wrapper = styled(Container)`
  display: block;
  margin: auto;
`
export const Row = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${media.medium`
  display: grid;

  grid-template-columns: 3fr 1fr 1fr 2fr;
  grid-template-areas: 'address betmax max multiplier' 'cashaddr betnumber min odds';
`}
`

export const Item = styled.div`
  ${props =>
    props.name &&
    css`
      grid-area: ${props.name};
    `}
  padding: 10px;
  overflow: hidden;
`

export const Title = styled.div`
  font-weight: 700;
`

export const Content = styled.div``
