import React from 'react'

import Layout from 'components/Layout'
import RulesContent from 'components/RulesContent'
class FaqPage extends React.Component {
  render() {
    const { pageContext } = this.props
    const { locale } = pageContext
    return (
      <Layout locale={locale} layout="dice">
        <RulesContent />
      </Layout>
    )
  }
}
export default FaqPage
