// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getGamerules } from 'data/gamerules/selectors'
import { Wrapper, Row, Item, Title, Content } from './styled'

const GamesList = ({ gamerules, intl: { messages } }) => (
  <Wrapper>
    {gamerules.map((item, index) => {
      const odds = `${(item.betnumber / item.betmax) * 100}%`
      return (
        <Row key={item.address}>
          <Item name="address">
            <Title>{messages.Winlist.address}</Title>
            <Content>{item.address}</Content>
          </Item>
          <Item name="cashaddr">
            <Title>{messages.Winlist.cashaddr}</Title>
            <Content>{item.cashaddr}</Content>
          </Item>
          <Item name="betmax">
            <Title>{messages.Betinfo.maxroll}</Title>
            <Content>{item.betmax}</Content>
          </Item>
          <Item name="betnumber">
            <Title>{messages.Fair.betnumber}</Title>
            <Content>{item.betnumber}</Content>
          </Item>
          <Item name="max">
            <Title>{messages.Winlist['bet-max']}</Title>
            <Content>{item.max}</Content>
          </Item>
          <Item name="min">
            <Title>{messages.Winlist['bet-min']}</Title>
            <Content>{item.min}</Content>
          </Item>
          <Item name="multiplier">
            <Title>{messages.Winlist['bet-multiplier']}</Title>
            <Content>{item.multiplier}</Content>
          </Item>
          <Item name="odds">
            <Title>{messages.Winlist.odds}</Title>
            <Content>{odds}</Content>
          </Item>
        </Row>
      )
    })}
  </Wrapper>
)

const mapStateToProps = state => {
  const { orderedOdds } = getGamerules(state)
  return {
    gamerules: orderedOdds,
  }
}

export default connect(mapStateToProps)(injectIntl(GamesList))
