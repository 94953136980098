import React from 'react'

import { injectIntl } from 'react-intl'
import Hero from 'components/Hero'
import H1 from 'atoms/H1'
import GamesList from 'components/GamesList'
import { Wrapper } from './styled'

class RulesContent extends React.Component {
  render() {
    const {
      intl: { messages },
    } = this.props
    const rulesContent = messages.Rules['rules-content']
    const faqContent = messages.Rules['faq-content']

    return (
      <div>
        <Hero small>
          <H1 thin>{messages.Rules['rules-title']}</H1>
        </Hero>
        <Wrapper>
          {rulesContent.map((item, idx) => {
            return (
              <div key={idx}>
                <h2>{item.title}</h2>
                {item.content.map((i, id) => (
                  <p key={id} dangerouslySetInnerHTML={{ __html: i }} />
                ))}
              </div>
            )
          })}
        </Wrapper>
        <Hero small>
          <H1>{messages.Rules['faq-title']}</H1>
        </Hero>
        <Wrapper>
          {faqContent.map((item, idx) => {
            return (
              <div key={idx}>
                <h2>{item.title}</h2>
                {item.content.map((i, id) => (
                  <p key={id}>{i}</p>
                ))}
              </div>
            )
          })}
        </Wrapper>
        <Hero small>
          <H1>{messages.Rules['available-games']}</H1>
        </Hero>
        <GamesList />
      </div>
    )
  }
}

export default injectIntl(RulesContent)
