import React from 'react'
import Container from 'atoms/Container'
import { Wrapper } from './styled'

class Hero extends React.Component {
  render() {
    return (
      <Wrapper small={this.props.small}>
        <Container>{this.props.children}</Container>
      </Wrapper>
    )
  }
}

export default Hero
